<template>
  <!-- 整体页面 -->
  <div class="homePage">
    <!-- 第一行数据 -->
    <div class="firstline flexspb">
      <!-- 左边的轮播图 -->
      <div class="banner centent">
        <banner @imglink="imglink" :imglist="imglist" />
      </div>
      <!-- 右边的新闻列表 -->
      <div class="centent">
        <newcard @morelink='morelink' :pagenum="newsPrimary" :chinum='0' title="动态" :list="worknews" />
      </div>
    </div>
    <!-- 第二行数据 -->
    <div class="secondline flexspb">
      <!-- 右边的新闻列表 -->
      <div class="centent">
        <newcard @morelink='morelink' :pagenum="newsPrimary" :chinum='1' title="通知" :list="worknotice" />
      </div>
      <!-- 右边的新闻列表 -->
      <div class="centent">
        <newcard @morelink='morelink' :pagenum="newsPrimary" :chinum='2' title="基准工具" :list="proclamation" />
      </div>
    </div>
    <div class="thirdline flexspb2">
      <div class="thirdLineTitle">成员单位:</div>
      <el-carousel 
        :interval="3000" 
        height="4rem" 
        class="footerCarousel"
      >
        <el-carousel-item v-for="(item, index) in footerList" :key="index">
          <!-- <h3 class="medium">{{ 111 }}</h3> -->
          <div v-for="(pic, index) in item" class="company" :key="index">
            <img 
              :src="pic.src"
              :alt="`${pic.partnerName}`"
              :id="`${pic}_${index}`"
            >
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 第三行数据 -->

    <!-- <div class="thirdline flexspb">
      <div @click='morelink({pagenum:2,chinum:0})' class="btn centent flexcent">
        <i class="el-icon-cpu"></i>
        <div>通用计算基准</div>
      </div>
      <div @click='morelink({pagenum:2,chinum:1})' class="btn centent flexcent">
        <i class="el-icon-monitor"></i>
        <div>通用服务器基准</div>
      </div>
    </div> -->
    
    <!-- 第四行数据 -->
    <!-- <div class="fourthline flexspb"> -->
      <!-- 右边的新闻列表 -->
      <!-- <div class="centent">
        <newcard @morelink='morelink' :pagenum='3' :chinum='0' title="通用计算基准榜单" :list="calculator" />
      </div> -->
      <!-- 右边的新闻列表 -->
      <!-- <div class="centent">
        <newcard @morelink='morelink' :pagenum='3' :chinum='1' title="通用服务器基准榜单" :list="theserver" />
      </div>
    </div> -->
    
    <!-- 第五行数据 -->
    <!-- <div class="fifthline flexspb" v-if="false">
      <div @click='morelink({pagenum:3,chinum:2})' class="btn flexcent">我要打榜</div>
    </div> -->
    <!-- 第六行数据 -->
    <!-- <div class="sixthline flexspb"> -->
      <!-- 右边的新闻列表 -->
      <!-- <div class="centent">
        <system @morelink='morelink'/>
      </div> -->
      <!-- 右边的新闻列表 -->
      <!-- <div class="centent">
        <newcard @morelink='morelink' :pagenum='3' :chinum='1' title="标准明细" :list="standardlist" />
      </div> -->
    <!-- </div> -->
    <!-- 第七行数据 -->
    <!-- <div class="seventhline">
      <tools @morelink='morelink'/>
    </div> -->
  </div>
</template>

<script>
import banner from "./banner"; //封装的轮播图
import newcard from "./newcard"; //封装的新闻卡片
// import system from "./system"; //封装的标准体系
// import tools from "./tools"; //封装的标准体系
const newsPrimary = -1
// 工作组活动删掉了，但是代码中通知的呈现依赖primary = 1,
const titleWithPushTime = (data) => {
  let d = data.map(item => {
      let o = {
        ...item,
        title: `${item.pushTime} ${item.title}`
      } 
      return o
  });
  return d
}
export default {
  name: "hopage", //首页页面
  data() {
    return {
      imglist: [], //轮播图数组
      worknews: [], //工作动态数据
      worknotice: [], //工作通知数据
      proclamation: [], //工作公告
      calculator: [], //计算机数据
      theserver: [], //服务器数据
      standardlist:[],//标准明细数据
      //合作单位列表
      footerList: [],
      newsPrimary,
    };
  },
  components: {
    banner, //封装的轮播图
    newcard, //封装的新闻卡片
    // system,//标准体系
    // tools,//工具下载
  },
  methods: {
    // 点击更多进行跳转
    morelink(e){
      this.$emit('changenar',e)
    },
    // 获取轮播图数据
    getbanner() {
      // 获取轮播图数据
      // console.log('iiii')
      // http://127.0.0.1:8080/cms/partnerLogo/getPartnerLogos
      this.$api.getFooterList().then((res) => {
        // console.log('footerList', res.data, window.location.host)
        // let host = window.location.host
        let list = res.data.map((item) => {
          let src = `/admin/sys-file/cppb/${item.partnerLogo}`
          return {
            ...item,
            src,
          }
          // http://localhost:8080/admin/sys-file/cppb/阿里云计算有限公司.jpg
        })
        let result = []
        let i = 0
        while (i <= list.length) {
          let item = list.slice(i, i + 9)
          item = item.concat(list.slice(0, 9 - item.length))
          result.push(item)
          i += 9
        }
        this.footerList = result
        // this.footerList = this.footerList.concat(this.footerList)
        // console.log('footer', this.footerList)
      })
      // http://10.166.146.7:8080/admin/sys-file/cppb/%E9%98%BF%E9%87%8C%E4%BA%91%E8%AE%A1%E7%AE%97%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8.jpg
      this.$api.getBannerList().then((res) => {
        // console.log('getbannerList', res.data)
        this.imglist = res.data;
      });
    },
    // 点击轮播图触发
    imglink(obj) {
      console.log(obj);
    },
    // 获取工作组活动数据
    getnewlist() {
      // 获取新闻动态列表
      this.getWorking(1, 7).then((data) => {
        // this.worknews = data;
        this.worknews = titleWithPushTime(data);

      });
      // 新闻通知列表
      this.getWorking(2, 4).then((data) => {
        // console.log('data', data)
        // data.forEach(item => {
        //   let {pushTime, title} = item
        //   item.title = `${pushTime} ${title}`
        // });
        this.worknotice = titleWithPushTime(data);
      });
      // 新闻公告列表
      this.getWorking(3, 4).then((data) => {
        // this.proclamation = data;
        this.proclamation = titleWithPushTime(data);
        let head = {
          title: "★CPUBench性能数据: ★",
          type: "static",
          id: 1,
          chinum: 0,
          pagenum: 2,
        }
        this.proclamation = [head].concat(this.proclamation)
        // console.log('this.proclamation', this.proclamation)
      });
    },
    // 封装获取新闻列表
    async getWorking(id, num) {
      return new Promise((resolve) => {
        this.$api
          .getWorkingList({
            current: 1, //当前页
            size: num, //页码数量
            title: "", //标题
            status: 1, //状态
            newsType: id, //活动分类
          })
          .then((res) => {
            resolve(res.data.records);
          });
      });
    },
    // 获取计算基准榜单列表
    getboardlist() {
      // 改成查询大榜单 在原有榜单上又加了一层
      this.getBigBillList(10).then((data) => {
        this.calculator = data;
      });
      this.getBigBillList(11).then((data) => {
        this.theserver = data;
      });
      // this.getbillboard(10).then((data) => {
      //   this.calculator = data;
      // });
      // this.getbillboard(11).then((data) => {
      //   this.theserver = data;
      // });
    },
    // 大榜单接口分装 在原有榜单上又加了一层
    async getBigBillList(num) {
      return new Promise((resolve) => {
        this.$api
          .bigBillboardList({
            current: 1, //当前页
            size: 20, //页码数量
            cateroyCode: num, //活动分类
            name: "", //大榜单名称
          })
          .then((res) => {
            resolve(res.data.records);
          });
      });
    },
    // 封装获取计算基准榜单列表
    async getbillboard(id) {
      return new Promise((resolve) => {
        this.$api
          .billboardList({
            current: 1, //当前页
            size: 4, //页码数量
            title: "", //标题
            status: 1, //状态
            cateroyCode: id, //活动分类
            name: "", //榜单名称
          })
          .then((res) => {
            resolve(res.data.records);
          });
      });
    },
    // 获取标准明细接口
    getstandardlist() {
      // 发起请求
      this.$api
        .getCriterionLiCst({
          current: 1, //当前页
          size: 5, //页码大小
          status: 1, //状态
          name: "", //标准名称
          cateroyCode: -1,
          standardType:'-1',
        })
        .then((res) => {
          this.standardlist = res.data.records;
        });
    },
  },
  mounted() {
    // 获取轮播图数据
    this.getbanner();
    // 获取工作组活动数据
    this.getnewlist();
    // 获取计算基准榜单列表
    this.getboardlist();
     // 获取标准明细接口
    this.getstandardlist();

    let f = () => {
      for (const item in 3) {
        console.log('item', item)
      }
    }
    f()
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {

  // 第一行的高度
  .firstline {
    height: 20rem;
  }
  //   第二行的高度
  .secondline {
    height: 17.5rem;
  }
  // 第四行数据
  .fourthline {
    height: 12rem;
  }
  // 第五行数据
  .fifthline {
    height: 7.5rem;
    .btn {
      width: 100%;
      font-size: 2rem;
      letter-spacing: 1rem;
    }
  }
  // 第六行数据
  .sixthline{
    height: 14rem;
  }
  .seventhline{
    margin: 1.25rem 0;
  }
  // 公告样式
  .flexspb {
    margin-top: 1.25rem;
    // 公共的样式
    .centent {
      flex: 1;
      height: 100%;
    }
    .centent:last-child {
      margin-left: 1.25rem;
    }
    // 公共按钮
    .btn {
      height: 7.5rem;
      background-color: #e8f4ff;
      color: #2c2a2a;
      box-shadow: 0 0.125rem 0.3125rem #d1e5ff;
      i {
        width: 3.125rem;
        margin: 0 4.5rem;
        font-size: 3.125rem;
      }
      div {
        flex: 1;
        font-size: 1.625rem;
        letter-spacing: 1rem;
      }
    }
    .btn:hover {
      color: #ffffff;
      background-color: #8a99ee;
      box-shadow: 0 0.125rem 0.3125rem #8494f1;
    }
    .btn:active {
      opacity: 0.7;
    }
    .thirdLineTitle {
      margin-bottom: 0.5rem;
    }
    .company {
      height: 3rem;
      margin-right: 0.2rem;
      // width: 3rem;
    }
  }
  .flexspb2 {
    margin-top: 1.5rem;
    /deep/ .el-carousel__container {
      text-align: center;
      height: 100%;
      // 轮播图的箭头样式
      .el-carousel__arrow {
        font-size: 1rem;
        background-color: rgba(15, 109, 251, 0.5);
        height: 4rem;
        width: 1rem;
        border-radius: 0px;
        // margin: 0, 0;
        // 轮播图的右箭头
        .el-icon-arrow-right {
          // margin-left: -1.5rem;
          transform: translateY(20%);
          top: 50%;
        }
      }
      .el-carousel__arrow--right {
          right: 0.05rem;
        }
    }
    // 轮播图的指示器
    .thirdLineTitle {
      margin-bottom: 0.5rem;
    }
    .footerCarousel {
      // transform: translateX(0.5rem);
    }
    .company {
      height: 100%;
      // margin-right: 0.2rem;
      // width: 3rem;
      display: inline-block;
      // 刚好显示9个图标的宽度，不要改
      width: 8.65rem;
      background-color: white;
      margin: 0 0.1rem;
      text-align: center;
      overflow: hidden;
      // vertical-align: middle;
      img {
        position: relative;
        width: 100%;
        // height: 100%;
        // width: 100%;
        transform: translateY(-50%);
        top: 50%;
        overflow: hidden;
      }
    }
  }
}

// http://10.166.146.7:8080/admin/sys-file/cppb/北京尖峰新锐信息科技研究院有限公司.png

@media screen and (max-width: 1023px) {
  // 第一行的高度
  .firstline {
    width: 100%;
    .banner{
      width: 100%;
      height: 10rem;
      /deep/ .el-carousel__container{
        height: 10rem;
      }
    }
  }
  
 
  // 第五行数据
  .fifthline {
    .btn {
      width: 100%;
      font-size: 2rem;
      letter-spacing: 1rem;
    }
  }
 
  .seventhline{
    margin: 1.25rem 0;
  }
  // 公告样式
  .flexspb {
    padding:  .5rem .625rem 0;
    box-sizing: border-box;
    flex-wrap: wrap;
    .centent{
      width: 100%;
    }
    .centent:last-child {
        margin-top: .625rem;
    }
    // 公共按钮
    .btn {
      height: 6rem;
      background-color: #e8f4ff;
      color: #2c2a2a;
      box-shadow: 0 0.125rem 0.3125rem #d1e5ff;
      i {
        width: 2.5rem;
        margin: 0 2.5rem;
        font-size: 2.5rem;
      }
      div {
        flex: 1;
        font-size: 1.2rem;
        letter-spacing: .5rem;
      }
    }
    .btn:hover {
      color: #ffffff;
      background-color: #8a99ee;
      box-shadow: 0 0.125rem 0.3125rem #8494f1;
    }
    .btn:active {
      opacity: 0.7;
    }

  }
}
</style>