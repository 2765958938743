<template>
  <!-- 首页代码 -->
  <div class="mainpage">
    <!-- 封装的头部和尾部的组件 -->
    <pagebox
      v-if="istrue"
      @changenar="changenar"
      :pagenum="primary"
      :chinum="secondary"
    >
      <div id="homebox">
        <div id="pagebox">
          <!-- 进入到首页 -->
          <div v-if="primary == 0">
            <hopage @changenar="changenar" />
          </div>
          <!-- 进入到工作组活动 -->
          <div v-if="primary != 0" class="flexspb orderbox">
            <div class="lefttab" v-if="primary != 2">
              <leftab
                :class="{ posky: scrolltop >= 96 }"
                @changenar="changenar"
                :pagenum="primary"
                :chinum="secondary"
              />
            </div>
            <!-- 封装的工作组活动页面 -->
            <div class="rightbox" v-if="primary == 1">
              <workgroup :chinum="secondary" />
            </div>
            <!-- 封装的性能基准页面 -->
            <!-- <div class="rightbox" v-if="primary == 2">
              <nature @scollist="scollist" :chinum="secondary" />
            </div> -->
            <!-- 封装的基准榜单页面 -->
            <div class="rightbox" v-if="primary == 3">
              <billboard @scollist="scollist" :chinum="secondary" />
            </div>
            <!-- //封装的基准标准页面 -->
            <div class="rightbox" v-if="primary == 4">
              <criterion :chinum="secondary" />
            </div>
            <!-- 封装的工具下载页面 -->
            <div class="rightbox" v-if="primary == 5">
              <tools @scollist="scollist" :chinum="secondary" />
            </div>
            <!-- 封装的关于我们页面 -->
            <!-- <div class="rightbox" v-if="primary == 6">
              <about
                @scollist="scollist"
                @changenar="changenar"
                :chinum="secondary"
              />
            </div> -->
          </div>
        </div>
      </div>
    </pagebox>
  </div>
</template>

<script>
import pagebox from "../../components/page/pagebox"; //封装的头部和尾部的组件
import hopage from "../../components/page/home/hopage"; //封装的首页页面
import leftab from "../../components/all/leftab"; //封装的公共二级导航
import workgroup from "../../components/page/work/workgroup"; //封装的工作组活动页面
// import nature from "../../components/page/property/nature"; //封装的性能基准页面
import billboard from "../../components/page/billboard/billboard"; //封装的基准榜单页面
import criterion from "../../components/page/criterion/criterion"; //封装的基准标准页面
import tools from "../../components/page/tooldown/tools"; //封装的工具下载页面
// import about from "../../components/page/about/about"; //封装的关于我们页面

export default {
  data() {
    return {
      primary: 0, //一级菜单的选中样式
      secondary: 0, //二级菜单的选中样式
      istrue: true, //刷新页面
      scroll: [], //当前滚动数组
      scrolltop: 0, //当前滚动条高度
    };
  },
  components: {
    pagebox, //全局引用页面样式
    hopage, //首页页面
    workgroup, //工作组活动页面
    leftab, //封装的公共二级导航
    // nature, //封装的性能基准页面
    billboard, //封装的基准榜单页面
    criterion, //封装的基准标准页面
    tools, //封装的工具下载页面
    // about, //封装的关于我们页面
  },
  created() {
    // 页面跳回
    if (this.$route.params.pagenum > -1) {
      this.primary = this.$route.params.pagenum; //一级菜单的选中样式
      this.secondary = this.$route.params.chinum;
    }
  },
  mounted() {
    if (window.innerWidth > 1023) {
      this.handleScroll();
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  //销毁,否则跳到别的路由还是会出现
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 滚动事件
    handleScroll() {
      // 添加防抖
      this.until.debounce(200).then(() => {
        this.scrolltop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (this.primary > 1 && this.primary != 4) {
          for (let a = 0; a < this.scroll.length; a++) {
            if (
              a + 1 == this.scroll.length &&
              this.scrolltop >= this.scroll[a]
            ) {
              var second = a;
            } else if (
              this.scrolltop >= this.scroll[a] &&
              this.scrolltop < this.scroll[a + 1]
            ) {
              second = a;
            }
          }
          if (this.primary != 6 || (this.primary == 6 && this.secondary != 2)) {
            if (this.secondary != second) {
              this.secondary = second;
              this.refresh();
              //   this.changenar({ pagenum: this.primary, chinum: this.secondary });
            }
          }
        }
      });
    },
    // 传出当前滚动数组
    scollist(list) {
      this.scroll = list;
    },
    // 刷新当前页面
    refresh() {
      this.istrue = false;
      this.$nextTick(() => {
        this.istrue = true;
      });
    },
    // 接受传输过来的值进行页面的切换
    changenar(e) {
      if (window.innerWidth > 1023) {
        if (this.primary != e.pagenum) {
          document.documentElement.scrollTop = 0;
        } else {
          if (this.primary > 1 && this.primary != 4) {
            document.documentElement.scrollTop = this.scroll[e.chinum];
            if (this.primary == 6 && e.chinum == 2) {
              document.documentElement.scrollTop = 0;
            }
          } else {
            document.documentElement.scrollTop = 0;
          }
        }
      }
      this.primary = e.pagenum; //一级菜单的选中样式
      this.secondary = e.chinum; //二级菜单的选中样式
      console.log('e', e)
      if (this.primary != 0) {
        //  进行路由跳转
        var list = this.until.cacheSync("get", "navigation").data;
        console.log('this.p', list[this.primary].path)
        this.until.jump.call(this, list[this.primary].path, "push", {
          pagenum: this.primary,
          chinum: this.secondary,
        });
      }
      this.refresh();
    },
  },
};
</script>

<style scoped lang="less">
// 当前页面最大的盒子（配置背景色
.mainpage {
  min-height: 100vh;
  width: 100%;
  background-color: #e2e2e217;
}

@media screen and (min-width: 1024px) {
  .orderbox {
    align-items: initial;
    margin-top: 1.25rem;
  }
  .lefttab {
    width: 15rem;
    margin-right: 0.625rem;
  }
  .posky {
    position: fixed;
    width: 15rem;
    top: 5rem;
    transition: top 0.5s;
  } 
  .rightbox {
    flex: 1;
  }
  #homebox {
    background-image: url(../../assets/homeBg.png);
    background-size:100% 100%;
    background-repeat: no-repeat;
    min-height: 80vh;
  }
}

@media screen and (max-width: 1023px) {
  .orderbox {
    margin: 0.625rem;
  }
  .lefttab {
    display: none;
  }
  .rightbox {
    flex: 1;
  }
}
</style>
