<template>
  <div class="box outside">
    <card :title="title" @morelink='morelink'>
      <div v-if="list.length == 0">暂无数据</div>
      <div @click="todetail(item)" :class="`boxcent ${item.type === 'static' ? 'highlight' : ''}`" v-for="(item, index) in list" :key="index">
        <span>{{ item.title }}</span>
        <span>{{ item.name }}</span>
      </div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "newcard", //首页新闻展示
  props: {
    // 内容的数组
    list: {
      typeof: [Array],
    },
    // 卡片标题
    title: {
      typeof: [String],
    },
    // 右边的更多按钮
    more: {
      typeof: [String],
      default: "更多",
    },
    // 卡片标题
    pagenum: {
      typeof: [Number],
    },
    // 右边的更多按钮
    chinum: {
      typeof: [Number],
    },
  },
  components: {
    card,
  },
  mounted() {
   
  },
  methods:{
     morelink(){
      this.$emit('morelink',{pagenum:this.pagenum,chinum:this.chinum})
    },
    todetail(item){
      let {id, type} = item
      var obj = {
        id: id,
        chinum: this.chinum,
        pagenum: this.pagenum,
      };
      if (type === "static") {
        // 假如是固定的跳转链接，chinum、pagenum从item里面取
        obj.chinum = item.chinum
        obj.pagenum = item.pagenum
      }
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    }
  }
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .outside {
    background-color: white;
    border: 1px solid #ebeef5;
    border-radius: 0.3rem;
    overflow: hidden;
  }
  .box {
    width: 100%;
    height: 100%;

    .boxcent {
      width: 32.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .boxcent:hover{
        color: #1639af;
        text-decoration: underline ;
    }
    .highlight {
        color: #1639af;
        text-decoration: underline ;
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
      font-size: .875rem;

    .boxcent {
      width:20rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .boxcent:hover{
        color: #1639af;
        text-decoration: underline ;
    }
  }
}
</style>
