<template>
  <!-- 轮播图盒子 -->
  <div class="box">
    <!-- 轮播图 -->
    <el-carousel class="bannar box" :interval="2000">
      <!--  -->
      <el-carousel-item v-for="(item, i) in imglist" :key="i">
        <img @click="imglink(item)" :src="srcs + item.imgUrl + '/' + item.imgName" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "banner", //轮播图页面
  props: {
    // 轮播图数组
    imglist: {
      typeof: [Array],
    },
  },
  data() {
    // console.log('inglist', this.imglist)
    return {
      srcs: this.$base.baseUrl + "/admin/sys-file/", //公共的请求头
    };
  },
  methods: {
    // 点击图片时触发
    imglink(obj) {
      // 传出点击的item
      this.$emit('imglink',obj)
    },
  },
};
</script>


<style scoped lang="less">
img{
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .el-carousel__container {
      height: 100%;
      // 轮播图的箭头样式
      .el-carousel__arrow {
        font-size: 1.5rem;
        background-color: transparent;
        // 轮播图的右箭头
        .el-icon-arrow-right {
          margin-left: -1.5rem;
        }
      }
    }
    // 轮播图的指示器
    /deep/ .el-carousel__indicator {
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
      margin: 0.3125rem;
      .el-carousel__button {
        width: 0.625rem;
        background-color: #3987b5;
        height: 0.625rem;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .el-carousel__container {
      height: 100%;
      // 轮播图的箭头样式
      .el-carousel__arrow {
        font-size: 1.5rem;
        background-color: transparent;
        // 轮播图的右箭头
        .el-icon-arrow-right {
          margin-left: -1.5rem;
        }
      }
    }
    // 轮播图的指示器
    /deep/ .el-carousel__indicator {
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
      margin: 0.3125rem;
      .el-carousel__button {
        width: 0.625rem;
        background-color: #3987b5;
        height: 0.625rem;
        border-radius: 50%;
      }
    }
  }
}
</style>
